import find from 'lodash/find';
import Auth from '../../plugins/Auth';
import store from '../../store';
import { getRouteNamespace } from '../../store/modules/info';

export default function (code) {
  return async ({ next }) => {
    if (!find(Auth.instance.user.roles, ['code', code])) {
      store.commit('info/setError', {
        code: 'middleware_role',
        title: 'Akses Ditolak',
      });
    }

    next();
  };
}

/**
 * Redirect to user role page.
 */
export const roleRedirectMiddleware = async ({ next }) => {
  const handle = () => {
    let path = undefined;

    // Set path based on the user role
    if (Auth.instance.isLoggedIn) {
      const role = Auth.instance.user.roles[0];

      if (role) {
        path = getRouteNamespace(role.code);
      }
    }

    next(path);
  };

  if (Auth.instance.isLoading) {
    Auth.instance.watch('isLoading', handle, { once: true });
  } else {
    handle();
  }
};
