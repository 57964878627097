import { Model } from '@vuex-orm/core';
import dayjs from '../../bootstrap/dayjs';
import Attendance from './Attendance';
import Grade from './Grade';
import Subject from './Subject';
import Teacher from './Teacher';

export default class Meeting extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'meetings';

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      grade_id: this.attr(null),
      subject_id: this.attr(null),
      teacher_id: this.attr(null),
      date: this.attr(null),
      time_start: this.attr(null),
      time_end: this.attr(null),
      attendances: this.hasMany(Attendance, 'meeting_id'),
      grade: this.belongsTo(Grade, 'grade_id'),
      subject: this.belongsTo(Subject, 'subject_id'),
      teacher: this.belongsTo(Teacher, 'teacher_id'),
    };
  }

  get dateJS() {
    return dayjs(this.date);
  }
}
