import axios from 'axios';
const qs = require('qs');
import config from '../config';
import store from '../store';

const getBaseURL = () => `${config.api.url}/${store.getters['info/namespace']}`;
const api = axios.create({
  baseURL: getBaseURL(),
  paramsSerializer: function (params) {
    return qs.stringify(params, { encode: false });
  },
});

store.watch(getBaseURL, (baseURL) => {
  api.defaults.baseURL = baseURL;
});

export default api;
