<template>
  <aside :class="{ 'is-visible': $store.state.info.isLayoutSidebarVisible }">
    <div
      class="backdrop d-lg-none vw-100 vh-100 fixed-top"
      @click="$store.commit('info/setIsLayoutSidebarVisible', false)"
    >
      <button
        class="
          btn-plain
          fs-28
          lh-1
          text-white
          position-absolute
          top-0
          end-0
          m-16
        "
      >
        <XIcon />
      </button>
    </div>

    <div class="inner vh-100 py-32">
      <div class="mx-24">
        <img
          alt="E-learning"
          class="mx-24 mb-16"
          src="../../assets/img/logo.png"
          width="48"
        />
      </div>

      <div class="mt-32 mx-24" v-for="(menu, i) of menus" :key="i">
        <h3
          class="text-muted fs-11 ls-02 text-uppercase ms-24 mb-24"
          v-if="menu.label"
        >
          {{ menu.label }}
        </h3>

        <div>
          <router-link
            class="
              d-flex
              align-items-center
              text-decoration-none text-hover-primary
              py-16
              px-24
              rounded-8
              mt-8
              position-relative
            "
            v-for="item of menu.items"
            :key="item.label"
            :to="item.to"
          >
            <component class="me-24" height="24" width="24" :is="item.icon" />
            {{ item.label }}
          </router-link>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import CalendarWeekIcon from 'bootstrap-icons/icons/calendar-week.svg';
import CardListIcon from 'bootstrap-icons/icons/card-list.svg';
import Easel2Icon from 'bootstrap-icons/icons/easel2.svg';
import HouseDoorIcon from 'bootstrap-icons/icons/house-door.svg';
import JournalTextIcon from 'bootstrap-icons/icons/journal-text.svg';
import JournalsIcon from 'bootstrap-icons/icons/journals.svg';
import ListIcon from 'bootstrap-icons/icons/list.svg';
import MegaphoneIcon from 'bootstrap-icons/icons/megaphone.svg';
import PeopleIcon from 'bootstrap-icons/icons/people.svg';
import XIcon from 'bootstrap-icons/icons/x.svg';

export default {
  components: { XIcon },

  computed: {
    menus() {
      switch (this.$store.getters['info/namespace']) {
        case 'admin':
          return [
            {
              label: 'Dashboard',
              items: [
                { icon: HouseDoorIcon, label: 'Beranda', to: '/admin/beranda' },
              ],
            },
            {
              label: 'Kelas',
              items: [
                { icon: Easel2Icon, label: 'Kelas', to: '/admin/kelas' },
                {
                  icon: JournalsIcon,
                  label: 'Pelajaran',
                  to: '/admin/pelajaran',
                },
              ],
            },
            {
              label: 'Pengaturan',
              items: [
                {
                  icon: PeopleIcon,
                  label: 'User',
                  to: '/admin/user',
                },
                {
                  icon: MegaphoneIcon,
                  label: 'Pengumuman',
                  to: '/admin/pengumuman',
                },
              ],
            },
          ];

        case 'student':
          return [
            {
              label: 'Dashboard',
              items: [
                { icon: HouseDoorIcon, label: 'Beranda', to: '/siswa/beranda' },
              ],
            },
            {
              label: 'Kelas',
              items: [
                {
                  icon: CalendarWeekIcon,
                  label: 'Jadwal',
                  to: '/siswa/jadwal',
                },
                {
                  icon: CardListIcon,
                  label: 'Tugas',
                  to: '/siswa/tugas',
                },
              ],
            },
          ];

        case 'teacher':
          return [
            {
              label: 'Dashboard',
              items: [
                { icon: HouseDoorIcon, label: 'Beranda', to: '/guru/beranda' },
              ],
            },
            {
              label: 'Kelas',
              items: [
                {
                  icon: CalendarWeekIcon,
                  label: 'Jadwal',
                  to: '/guru/jadwal',
                },
                {
                  icon: ListIcon,
                  label: 'Rekap Presensi',
                  to: '/guru/rekap/presensi',
                },
                {
                  icon: JournalTextIcon,
                  label: 'Rekap Tugas',
                  to: '/guru/rekap/tugas',
                },
              ],
            },
          ];
      }

      return [];
    },
  },

  watch: {
    $route() {
      this.$store.commit('info/setIsLayoutSidebarVisible', false);
    },
  },
};
</script>

<style lang="scss" scoped>
aside {
  color: tint-color($body-color, 20%);
}

.backdrop {
  display: none;
  background-color: rgba($black, 0.5);
  backdrop-filter: blur(2px);

  .is-visible & {
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}

.inner {
  background: radial-gradient(
      ellipse at 100%,
      tint-color($primary, 90%),
      transparent
    )
    $body-bg;
  width: rem(280);
  max-width: calc(100vw - #{rem(52)});
  overflow-y: auto;
  z-index: $zindex-fixed;

  @include media-breakpoint-down(lg) {
    display: none;
    position: fixed;
  }

  .is-visible & {
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}

a {
  &:hover,
  &:focus {
    background: tint-color($primary, 90%);
  }

  &.router-link-active {
    color: $primary;

    &::before {
      content: '';
      width: rem(4);
      height: 100%;
      background: $primary;
      border-radius: rem(2);
      position: absolute;
      left: rem(-24);
    }
  }
}
</style>
