import api from '..';

export default {
  fetch(params) {
    return api.get('v1/answers', { params });
  },

  review(ID, data) {
    return api.patch(`v1/answers/${ID}/review`, data);
  },
};
