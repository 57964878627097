import qs from 'qs';
import Vue from 'vue';
import VueRouter from 'vue-router';
import middleware from './middleware';
import authMiddleware from './middleware/auth';
import guestMiddleware from './middleware/guest';
import roleMiddleware, { roleRedirectMiddleware } from './middleware/role';
import store from '../store';

import AuthLayout from '../layouts/AuthLayout';
import DefaultLayout from '../layouts/DefaultLayout';

Vue.use(VueRouter);

const routes = [
  /*
  |----------------------------------------------------------------------------
  | Landing route
  |----------------------------------------------------------------------------
  */

  {
    path: '/',
    component: () => import('../views/Index'),
    meta: {
      layout: AuthLayout,
      middleware: [roleRedirectMiddleware],
    },
  },

  /*
  |----------------------------------------------------------------------------
  | Admin routes
  |----------------------------------------------------------------------------
  */

  { path: '/admin', redirect: '/admin/beranda' },
  {
    path: '/admin/beranda',
    component: () => import('../views/admin/Index.vue'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/pengumuman',
    component: () => import('../views/admin/announcement/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/pengumuman/tambah',
    component: () => import('../views/admin/announcement/Add'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/pengumuman/:id/ubah',
    component: () => import('../views/admin/announcement/Edit'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/kelas',
    component: () => import('../views/admin/grade/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/kelas/tambah',
    component: () => import('../views/admin/grade/Add'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/kelas/:id/ubah',
    component: () => import('../views/admin/grade/Edit'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/kelas/:grade_id/jadwal',
    component: () => import('../views/admin/grade/schedule/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/kelas/:grade_id/jadwal/tambah',
    component: () => import('../views/admin/grade/schedule/Add'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/kelas/:grade_id/jadwal/:schedule_id/ubah',
    component: () => import('../views/admin/grade/schedule/Edit'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/pelajaran',
    component: () => import('../views/admin/subject/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/pelajaran/tambah',
    component: () => import('../views/admin/subject/Add'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/pelajaran/:id/ubah',
    component: () => import('../views/admin/subject/Edit'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/login',
    component: () => import('../views/admin/Login'),
    meta: { layout: AuthLayout, middleware: [guestMiddleware] },
  },
  {
    path: '/admin/user',
    component: () => import('../views/admin/user/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/user/tambah',
    component: () => import('../views/admin/user/Add'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },
  {
    path: '/admin/user/:id/ubah',
    component: () => import('../views/admin/user/Edit'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('admin')],
    },
  },

  /*
  |----------------------------------------------------------------------------
  | Student routes
  |----------------------------------------------------------------------------
  */

  { path: '/siswa', redirect: '/siswa/beranda' },
  {
    path: '/siswa/beranda',
    component: () => import('../views/teacher/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('student')],
    },
  },
  {
    path: '/siswa/login',
    component: () => import('../views/student/Login'),
    meta: { layout: AuthLayout, middleware: [guestMiddleware] },
  },
  {
    path: '/siswa/jadwal',
    component: () => import('../views/student/schedule/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('student')],
    },
  },
  {
    path: '/siswa/jadwal/:id/presensi',
    component: () => import('../views/student/schedule/Attendance'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('student')],
    },
  },
  {
    path: '/siswa/jadwal/:id/materi',
    component: () => import('../views/student/schedule/material/Index.vue'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('student')],
    },
  },
  {
    path: '/siswa/jadwal/:schedule_id/materi/:material_id',
    component: () => import('../views/student/schedule/material/View.vue'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('student')],
    },
  },
  {
    path: '/siswa/tugas',
    component: () => import('../views/student/task/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('student')],
    },
  },
  {
    path: '/siswa/tugas/:id/jawab',
    component: () => import('../views/student/task/Answer'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('student')],
    },
  },

  /*
  |----------------------------------------------------------------------------
  | Teacher routes
  |----------------------------------------------------------------------------
  */

  { path: '/guru', redirect: '/guru/beranda' },
  {
    path: '/guru/beranda',
    component: () => import('../views/teacher/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/login',
    component: () => import('../views/teacher/Login'),
    meta: { layout: AuthLayout, middleware: [guestMiddleware] },
  },
  {
    path: '/guru/rekap/presensi',
    component: () => import('../views/teacher/report/Attendances'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/rekap/tugas',
    component: () => import('../views/teacher/report/Tasks'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/jadwal',
    component: () => import('../views/teacher/schedule/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/jadwal/:id/pertemuan',
    component: () => import('../views/teacher/schedule/meeting/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/jadwal/:schedule_id/materi',
    component: () => import('../views/teacher/schedule/material/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/jadwal/:schedule_id/materi/tambah',
    component: () => import('../views/teacher/schedule/material/Add'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/jadwal/:schedule_id/materi/:material_id/ubah',
    component: () => import('../views/teacher/schedule/material/Edit'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/jadwal/:schedule_id/pertemuan/:meeting_id/presensi',
    component: () => import('../views/teacher/schedule/meeting/Students'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/jadwal/:schedule_id/tugas',
    component: () => import('../views/teacher/schedule/task/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/jadwal/:schedule_id/tugas/tambah',
    component: () => import('../views/teacher/schedule/task/Add'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/jadwal/:schedule_id/tugas/:task_id/ubah',
    component: () => import('../views/teacher/schedule/task/Edit'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/jadwal/:schedule_id/tugas/:task_id/jawaban',
    component: () => import('../views/teacher/schedule/task/answer/Index'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },
  {
    path: '/guru/jadwal/:schedule_id/tugas/:task_id/jawaban/:answer_id',
    component: () => import('../views/teacher/schedule/task/answer/Review'),
    meta: {
      layout: DefaultLayout,
      middleware: [authMiddleware, roleMiddleware('teacher')],
    },
  },

  /*
  |----------------------------------------------------------------------------
  | Else
  |----------------------------------------------------------------------------
  */

  {
    path: '*',
    meta: {
      middleware: [
        () =>
          store.commit('info/setError', { title: 'Halaman Tidak Ditemukan' }),
      ],
    },
  },
];

function resetBreadcrumb(to, from) {
  if (to.path !== from.path) {
    store.commit('info/setBreadcrumb', []);
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  parseQuery: qs.parse,
  stringifyQuery: (query) => {
    const string = qs.stringify(query, {
      arrayFormat: 'brackets',
      encode: false,
    });
    return string && `?${string}`;
  },
  routes,
});

/**
 * Redirect if no history back.
 *
 * @param {String|Object} location
 */
router.backOrPush = function (location) {
  if (window.history.length > 1) this.back();
  else this.push(location);
};

router.beforeEach((...args) => {
  resetBreadcrumb(...args);
  // Reset error
  store.commit('info/setError', null);
  middleware(...args);
});

router.afterEach((to) =>
  store.commit('info/setRouteNamespace', to.path.split('/')[1] || 'siswa')
);

export default router;
