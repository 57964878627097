import { Model } from '@vuex-orm/core';
import dayjs from '../../bootstrap/dayjs';
import Grade from './Grade';
import Subject from './Subject';
import Teacher from './Teacher';

export default class Schedule extends Model {
  static DAY_TEXT = [
    'Senin',
    'Selasa',
    'Rabu',
    'Kamis',
    "Jum'at",
    'Sabtu',
    // 'Minggu',
  ];

  // This is the name used as module name of the Vuex Store.
  static entity = 'schedules';

  static get dayOptions() {
    return this.DAY_TEXT.map((day, index) => ({
      label: day,
      value: index,
    }));
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      grade_id: this.attr(null),
      subject_id: this.attr(null),
      teacher_id: this.attr(null),
      day: this.attr(null),
      time_start: this.attr(null),
      time_end: this.attr(null),
      grade: this.belongsTo(Grade, 'grade_id'),
      subject: this.belongsTo(Subject, 'subject_id'),
      teacher: this.belongsTo(Teacher, 'teacher_id'),
    };
  }

  get attendanceRoutePath() {
    return `/siswa/jadwal/${this.id}/presensi`;
  }

  get dayText() {
    return Schedule.DAY_TEXT[this.day];
  }

  get editRoutePath() {
    return `/admin/kelas/${this.grade.id}/jadwal/${this.id}/ubah`;
  }

  get inPeriod() {
    const time = dayjs().format('HH:mm');
    return this.isToday && this.time_start <= time && this.time_end >= time;
  }

  get isMissed() {
    const time = dayjs().format('HH:mm');
    return this.isToday && this.time_end <= time;
  }

  get isToday() {
    return this.day === dayjs().weekday();
  }

  get materialsRoutePath() {
    const routeNamespace = this.$store().state.info.routeNamespace;
    return `/${routeNamespace}/jadwal/${this.id}/materi`;
  }

  get meetingsRoutePath() {
    return `/guru/jadwal/${this.id}/pertemuan`;
  }

  get scheduleRoutePath() {
    const routeNamespace = this.$store().state.info.routeNamespace;
    return `/${routeNamespace}/jadwal/?day=${this.day}`;
  }

  get tasksRoutePath() {
    return `/guru/jadwal/${this.id}/tugas`;
  }

  get timeText() {
    return `${this.time_start}&ndash;${this.time_end}`;
  }
}
