import Auth from "../../plugins/Auth";

export default function auth({ next }) {
  const handle = () => {
    const path = Auth.instance.isLoggedIn ? "/" : undefined;
    next(path);
  };

  if (Auth.instance.isLoading) {
    Auth.instance.watch("isLoading", handle, { once: true });
  } else {
    handle();
  }
}
