import dayjs from '../../bootstrap/dayjs';
import Answer from './Answer';
import Base from './Base';
import Grade from './Grade';
import Subject from './Subject';
import fileUtil from '../../utils/file';

export default class Task extends Base {
  // This is the name used as module name of the Vuex Store.
  static entity = 'tasks';

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      grade_id: this.attr(null),
      subject_id: this.attr(null),
      title: this.attr(null),
      content: this.attr(null),
      file: this.attr(null),
      due_time: this.attr(null),
      answers: this.hasMany(Answer, 'task_id'),
      grade: this.belongsTo(Grade, 'grade_id'),
      subject: this.belongsTo(Subject, 'subject_id'),
    };
  }

  get dueTimeJS() {
    return this.due_time && dayjs(this.due_time);
  }

  get fileURL() {
    return this.file && fileUtil.getURL(this.file);
  }

  get isPastDue() {
    return this.due_time && this.dueTimeJS < dayjs();
  }
}
