import get from 'lodash/get';
import store from '../store';

class Alert {
  static install(Vue) {
    const $alert = new Alert();
    Vue.prototype.$alert = $alert;
  }

  get data() {
    return store.state.alert.data;
  }

  /**
   * Alert.
   *
   * @param {String} message Message or object data.
   * @param {Object} options
   */
  show(message, options = {}) {
    const data = { message, theme: 'info', ...options };
    store.commit('alert/set', data);
  }

  error(message, options = {}) {
    this.show(message, { theme: 'danger', ...options });
  }

  success(message, options = {}) {
    this.show(message, { theme: 'success', ...options });
  }

  /**
   * Alert for request error.
   *
   * @param {Error} error
   * @param {Object} options
   */
  requestError(error, options) {
    let message = get(error, 'response.data.error.message') || error.message;

    this.error(message, options);
    console.error(error);
  }

  /**
   * Remove alert.
   */
  remove() {
    store.commit('alert/set', null);
  }
}

export default Alert;
