import Auth from "../../plugins/Auth";
import store from "../../store";

export default async ({ next }) => {
  const handle = () => {
    const namespace = store.state.info.routeNamespace;
    const path = Auth.instance.isLoggedIn ? undefined : `/${namespace}/login`;
    next(path);
  };

  if (Auth.instance.isLoading) {
    Auth.instance.watch("isLoading", handle, { once: true });
  } else {
    handle();
  }
};
