import Base from './Base';
import Role from './Role';
import RoleUser from './RoleUser';
import Student from './Student';
import Teacher from './Teacher';

export default class User extends Base {
  // This is the name used as module name of the Vuex Store.
  static entity = 'users';

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      email: this.attr(null),
      roles: this.belongsToMany(Role, RoleUser, 'user_id', 'role_code'),
      student: this.hasOne(Student, 'user_id'),
      teacher: this.hasOne(Teacher, 'user_id'),
    };
  }

  get avatarURL() {
    return `https://ui-avatars.com/api/?name=${this.name}&background=3326b2&color=ffffff`;
  }

  get editRoutePath() {
    return `/admin/user/${this.id}/ubah`;
  }

  get firstName() {
    return this.name.split(' ').shift();
  }
}
