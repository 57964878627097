import invert from 'lodash/invert';

const namespaces = { admin: 'admin', guru: 'teacher', siswa: 'student' };
const parseRouteNamespace = (path) =>
  Object.keys(namespaces).includes(path) ? path : 'siswa';

export const getRouteNamespace = (namespace) =>
  invert(namespaces)[namespace] || 'siswa';

export default {
  namespaced: true,

  state: () => {
    return {
      breadcrumb: [],
      error: null,
      isLayoutSidebarVisible: false,
      meta: {},
      routeNamespace: parseRouteNamespace(location.pathname.split('/')[1]),
    };
  },

  getters: {
    namespace: (state) => namespaces[state.routeNamespace],
  },

  mutations: {
    setBreadcrumb: (state, breadcrumb) => (state.breadcrumb = breadcrumb),
    setError: (state, error) => (state.error = error),
    setIsLayoutSidebarVisible: (state, isLayoutSidebarVisible) =>
      (state.isLayoutSidebarVisible = isLayoutSidebarVisible),
    setMeta: (state, meta) => (state.meta = meta),
    setRouteNamespace: (state, routeNamespace) =>
      (state.routeNamespace = parseRouteNamespace(routeNamespace)),
  },
};
