import Base from './Base';
import Announcement from './Announcement';
import AnnouncementRole from './AnnouncementRole';
import RoleUser from './RoleUser';
import User from './User';

export default class Role extends Base {
  static CODE_ADMIN = 'admin';
  static CODE_STUDENT = 'student';
  static CODE_TEACHER = 'teacher';

  static entity = 'roles';

  static primaryKey = 'code';

  static fields() {
    return {
      code: this.attr(null),
      name: this.attr(null),
      announcements: this.belongsToMany(
        Announcement,
        AnnouncementRole,
        'announcement_id',
        'role_code'
      ),
      users: this.belongsToMany(User, RoleUser, 'role_code', 'user_id'),
    };
  }
}
