<template>
  <div class="d-flex flex-column flex-md-row vh-100">
    <main class="flex-grow-1 flex-md-grow-0 d-flex flex-column container">
      <div class="inner flex-grow-1 d-flex flex-column mx-auto">
        <div class="my-32">
          <router-link to="/">
            <img alt="E-learning" src="../assets/img/logo.png" width="48" />
          </router-link>
        </div>

        <slot />
      </div>
    </main>

    <div class="img flex-md-grow-1"></div>

    <AlertModal />
  </div>
</template>

<script>
import AlertModal from '../components/alert/AlertModal.vue';

export default {
  components: { AlertModal },
};
</script>

<style lang="scss" scoped>
main {
  @include media-breakpoint-up(md) {
    width: rem(640);
  }
}

.inner {
  width: rem(300);
}

.img {
  height: 85px;
  background: url(../assets/img/login-img.jpg) top center/768px;

  @media (min-resolution: 192dpi) {
    background-size: 384px;
    height: 78px;
  }

  @include media-breakpoint-up(md) {
    height: 100%;
    background-position: top left;
  }
}
</style>
