import dayjs from '../../bootstrap/dayjs';
import Base from './Base';
import Meeting from './Meeting';
import Student from './Student';

export default class Attendance extends Base {
  static entity = 'attendances';

  static fields() {
    return {
      id: this.attr(null),
      meeting_id: this.attr(null),
      student_nisn: this.attr(null),
      created_at: this.attr(null),
      meeting: this.belongsTo(Meeting, 'meeting_id'),
      student: this.belongsTo(Student, 'student_nisn'),
    };
  }

  get createdAtJS() {
    return dayjs(this.created_at);
  }
}
