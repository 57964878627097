<template>
  <VDropdown>
    <!-- This will be the popover reference (for the events and position) -->
    <button class="trigger btn-plain" v-tooltip="$auth.user.name">
      <img
        class="rounded-circle me-8"
        :alt="$auth.user.name"
        :src="`${$auth.user.avatarURL}&size=36`"
      />
      <ChevronDownIcon />
    </button>

    <!-- This will be the content of the popover -->
    <template #popper>
      <div class="p-8">
        <div class="btn cursor-default">{{ $auth.user.name }}</div>
        <button
          class="btn text-danger d-flex align-items-center"
          @click="$auth.logout()"
        >
          <BoxArrowRightIcon class="me-8" /> Logout
        </button>
      </div>
    </template>
  </VDropdown>
</template>

<script>
import VDropdown from '../v-tooltip/VDropdown.vue';

import BoxArrowRightIcon from 'bootstrap-icons/icons/box-arrow-right.svg';
import ChevronDownIcon from 'bootstrap-icons/icons/chevron-down.svg';

export default {
  components: { VDropdown, BoxArrowRightIcon, ChevronDownIcon },
};
</script>

<style lang="scss" scoped>
.trigger {
  .v-popper--shown & {
    svg {
      transform: rotate(180deg);
    }
  }
}
</style>
