import Answer from './Answer';
import Attendance from './Attendance';
import Base from './Base';
import Grade from './Grade';
import User from './User';

export default class Student extends Base {
  // This is the name used as module name of the Vuex Store.
  static entity = 'students';

  static primaryKey = 'nisn';

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      nisn: this.attr(null),
      grade_id: this.attr(null),
      user_id: this.attr(null),
      nis: this.attr(null),
      answers: this.hasMany(Answer, 'student_nisn'),
      attendances: this.hasMany(Attendance, 'student_nisn'),
      grade: this.belongsTo(Grade, 'grade_id'),
      user: this.belongsTo(User, 'user_id'),
    };
  }
}
