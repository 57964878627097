import api from '..';

export default {
  fetchByMeetingId(meetingId) {
    return api.get(`v1/meetings/${meetingId}/students`);
  },

  fetchByTaskId(taskId) {
    return api.get(`v1/tasks/${taskId}/students`);
  },
};
