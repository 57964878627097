import dayjs from '../../bootstrap/dayjs';
import Base from './Base';
import Role from './Role';
import AnnouncementRole from './AnnouncementRole';
import fileUtil from '../../utils/file';

export default class Announcement extends Base {
  static entity = 'announcements';

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(null),
      content: this.attr(null),
      file: this.attr(null),
      created_at: this.attr(null),
      roles: this.belongsToMany(
        Role,
        AnnouncementRole,
        'announcement_id',
        'role_code'
      ),
    };
  }

  get createdAtJS() {
    return dayjs(this.created_at);
  }

  get editRoutePath() {
    return `/admin/pengumuman/${this.id}/ubah`;
  }

  get fileURL() {
    return this.file && fileUtil.getURL(this.file);
  }
}
