import get from 'lodash/get';
import api from '../api';
import router from '../router';
import store from '../store';
import User from '../store/models/User';

class Auth {
  get data() {
    return store.state.auth.data;
  }

  get user() {
    return this.data && User.query().withAll().find(this.data.id);
  }

  get isLoggedIn() {
    return Boolean(this.user);
  }

  get isLoading() {
    return store.state.auth.isLoading;
  }

  get routeNamespace() {
    return store.state.info.routeNamespace;
  }

  static install(Vue) {
    const auth = new Auth();
    Vue.prototype.$auth = auth;
  }

  constructor() {
    Auth.instance = this;

    this._initAPI();
    this._initRouter();
    this._load();
  }

  /**
   * Log in.
   *
   * @param {Object} credentials
   */
  async login(credentials) {
    const response = await api.post('v1/auth/login', credentials, {
      withCredentials: true,
    });
    const data = response.data.data;

    this._storeData(data);
    await this._fetchUser();
  }

  /**
   * Log out.
   */
  logout() {
    localStorage.removeItem('auth');
    store.commit('auth/setData', null);
  }

  watch(expression, callback, options) {
    const unwatch = store.watch(
      () => this[expression],
      function () {
        callback(...arguments);
        options && options.once && unwatch && unwatch();
      },
      options
    );
  }

  /**
   * Initialize Axios API integration.
   */
  _initAPI() {
    let isRefreshing = false;

    api.interceptors.response.use(
      // Any status code that lie within the range of 2xx cause this function to
      // trigger
      (response) => response,
      // Any status codes that falls outside the range of 2xx cause this
      // function to trigger
      async (error) => {
        const code = get(error, 'response.data.error.code');

        if (!isRefreshing && code === 'auth_token_expired') {
          try {
            isRefreshing = true;

            await this._refreshToken();

            error.config.headers.Authorization =
              api.defaults.headers.common['Authorization'];

            return api.request(error.config);
          } catch (err) {
            console.warn(err);
            this.logout();

            return Promise.reject(error);
          } finally {
            isRefreshing = false;
          }
        }

        return Promise.reject(error);
      }
    );
  }

  /**
   * Load using saved data.
   */
  async _load() {
    store.commit('auth/setIsLoading', true);

    try {
      const saved = JSON.parse(localStorage.getItem('auth') || null);

      if (saved) {
        this._storeData(saved);
        await this._fetchUser();
      }
    } finally {
      store.commit('auth/setIsLoading', false);
    }
  }

  _initRouter() {
    this.watch('isLoggedIn', (isLoggedIn) => {
      if (
        isLoggedIn &&
        router.currentRoute.path === `/${this.routeNamespace}/login`
      ) {
        router.push(`/${this.routeNamespace}/beranda`);
      } else if (
        !isLoggedIn &&
        router.currentRoute.path !== `/${this.routeNamespace}/login`
      ) {
        router.go();
      }
    });
  }

  async _refreshToken() {
    const response = await api.post('v1/auth/refresh-token', null, {
      withCredentials: true,
    });
    const data = response.data.data;

    this._storeData(data);
  }

  _storeData(data) {
    localStorage.setItem('auth', JSON.stringify(data));
    store.commit('auth/setData', data);
    this._setAPIToken();
  }

  /**
   * Set API bearer token.
   */
  _setAPIToken() {
    const token = `Bearer ${this.data.access_token}`;
    api.defaults.headers.common['Authorization'] = token;
  }

  /**
   * Fethc user data.
   */
  async _fetchUser() {
    const response = await api.get('v1/auth/me');
    User.insertOrUpdate({ data: response.data.data });
  }
}

export default Auth;
