<template>
  <div class="px-16 px-lg-32 pt-16 pb-32">
    <div class="d-flex align-items-center">
      <button
        class="d-lg-none btn-plain me-16"
        @click="$store.commit('info/setIsLayoutSidebarVisible', true)"
      >
        <ListIcon />
      </button>

      <h1 class="fs-28 mb-0 me-auto">
        {{ $store.state.info.meta.titleChunk }}
      </h1>

      <HeaderUser />
    </div>

    <nav
      class="fs-14 mt-16"
      aria-label="breadcrumb"
      v-if="$store.state.info.breadcrumb.length"
    >
      <ol class="breadcrumb mb-0">
        <li
          class="breadcrumb-item"
          :class="{ active: !item.path }"
          :key="item.label"
          v-for="item of $store.state.info.breadcrumb"
        >
          <router-link :to="item.path" v-if="item.path">
            {{ item.label }}
          </router-link>
          <template v-else>{{ item.label }}</template>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ $store.state.info.meta.titleChunk }}
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
import HeaderUser from './HeaderUser.vue';

import ListIcon from 'bootstrap-icons/icons/list.svg';

export default {
  components: { HeaderUser, ListIcon },
};
</script>
