<script>
import { Dropdown } from 'v-tooltip';
import 'v-tooltip/dist/v-tooltip.css';

export default Dropdown;
</script>

<style lang="scss">
.v-popper--theme-dropdown {
  .v-popper__inner {
    padding: rem(0);

    button {
      width: 100%;

      &:hover,
      &:focus {
        background: tint-color($primary, 90%);
      }
    }
  }
}
</style>
