import { Model } from '@vuex-orm/core';
import Material from './Material';
import Schedule from './Schedule';
import Student from './Student';
import Task from './Task';

export default class Grade extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'grades';

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      materials: this.hasMany(Material, 'grade_id'),
      schedules: this.hasMany(Schedule, 'grade_id'),
      students: this.hasMany(Student, 'grade_id'),
      tasks: this.hasMany(Task, 'grade_id'),
      students_count: this.attr(null),
    };
  }

  get editRoutePath() {
    return `/admin/kelas/${this.id}/ubah`;
  }

  get scheduleRoutePath() {
    return `/admin/kelas/${this.id}/jadwal`;
  }
}
