import Vue from 'vue';
import get from 'lodash/get';
import omit from 'lodash/omit';

export const createResourceModule = ({
  Model,
  repository,
  contentPath = 'data.data',
  metaPath = 'data.meta',
}) => {
  const getInitialState = () => ({
    isFetching: {},
    didFetch: {},
    result: {},
  });

  /**
   * Generate key from payload.
   *
   * @param {Object} payload
   * @returns {String}
   */
  const k = (payload = {}) => JSON.stringify(payload);

  return {
    namespaced: true,

    state: () => getInitialState(),

    getters: {
      isFetching:
        (state) =>
        (payload = {}) =>
          !!state.isFetching[k(payload)],

      didFetch:
        (state) =>
        (payload = {}) =>
          !!state.didFetch[k(payload)],

      getResult:
        (state) =>
        (payload = {}) =>
          state.result[k(payload)],

      get:
        (state, getters) =>
        (payload = {}) => {
          const result = getters.getResult(payload);
          const items = result
            ? result.items.map((ID) =>
                Model.query().withAllRecursive().find(ID)
              )
            : [];

          return items.filter((item) => item);
        },

      getMeta:
        (state, getters) =>
        (payload = {}) => {
          const result = getters.getResult(payload);
          return result && result.meta;
        },
    },

    mutations: {
      putIsFetching: (state, [key, isFetching]) =>
        Vue.set(state.isFetching, key, isFetching),

      putDidFetch: (state, [key, didFetch]) =>
        Vue.set(state.didFetch, key, didFetch),

      putResult: (state, [key, result]) => Vue.set(state.result, key, result),

      invalidate: (state) => Object.assign(state, getInitialState()),
    },

    actions: {
      fetch: async ({ commit }, payload = {}) => {
        const key = k(payload);
        const method = payload.$repositoryMethod || 'fetch';
        const params = payload.$repositoryParams || [
          omit(payload, '$repositoryMethod'),
        ];

        commit('putIsFetching', [key, true]);

        try {
          const response = await repository[method](...params);
          const items = get(response, contentPath);
          const IDs = items.map((item) => {
            Model.insertOrUpdate({ data: item });
            return get(item, Model.primaryKey);
          });
          const meta = get(response, metaPath);

          commit('putResult', [key, { items: IDs, meta }]);
          commit('putDidFetch', [key, true]);
        } finally {
          commit('putIsFetching', [key, false]);
        }
      },

      maybeFetch: async ({ dispatch, getters }, payload = {}) => {
        if (!getters.didFetch(payload) && !getters.isFetching(payload)) {
          await dispatch('fetch', payload);
        }
      },

      fetchAndInvalidate: async (
        { commit, dispatch, getters },
        payload = {}
      ) => {
        await dispatch('fetch', payload);

        const result = getters.getResult(payload);
        const key = k(payload);

        commit('invalidate');
        commit('putResult', [key, result]);
        commit('putDidFetch', [key, true]);
        commit('putIsFetching', [key, false]);
      },
    },
  };
};
