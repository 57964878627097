/* eslint-disable no-console */

import { register } from 'register-service-worker';
import api from './api';
import config from './config';
import Auth from './plugins/Auth';

/**
 * Convert base64 VAPID public key to Uint.
 *
 * @link https://gist.github.com/Klerith/80abd742d726dd587f4bd5d6a0ab26b6
 *
 * @param {string} base64String
 * @return {Uint8Array}
 */
function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

/**
 * Requesting permission to send them push messages.
 *
 * @return {Promise}
 */
function askNotificationPermission() {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then(function (permissionResult) {
    if (permissionResult !== 'granted') {
      throw new Error("We weren't granted permission.");
    }
  });
}

/**
 * Subscribe a User with PushManager.
 *
 * @param {ServiceWorkerRegistration} registration
 * @returns {Promise<Object>}
 */
function subscribeUserToPushManager(registration) {
  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(config.api.vapidPublicKey),
  };

  return registration.pushManager.subscribe(subscribeOptions);
}

/**
 * Send a Subscription to API Server.
 *
 * @param {Object} subscription
 * @returns {Promise}
 */
function sendSubscriptionToBackEnd(subscription) {
  return api.patch('/v1/push-subscription', subscription, {
    baseURL: config.api.url,
  });
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered(registration) {
      console.log('Service worker has been registered.');

      Auth.instance.watch(
        'isLoggedIn',
        (isLoggedIn) => {
          if (!isLoggedIn) return;

          askNotificationPermission()
            .then(() => subscribeUserToPushManager(registration))
            .then(sendSubscriptionToBackEnd);
        },
        { immediate: true }
      );
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
