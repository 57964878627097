import VuexORM from '@vuex-orm/core';
import Vue from 'vue';
import Vuex from 'vuex';

import alert from './modules/alert';
import announcements from './modules/announcements';
import answers from './modules/answers';
import auth from './modules/auth';
import confirmation from './modules/confirmation';
import grades from './modules/grades';
import info from './modules/info';
import materials from './modules/materials';
import meetings from './modules/meetings';
import roles from './modules/roles';
import schedules from './modules/schedules';
import students from './modules/students';
import subjects from './modules/subjects';
import tasks from './modules/tasks';
import teachers from './modules/teachers';
import users from './modules/users';

import Announcement from './models/Announcement';
import AnnouncementRole from './models/AnnouncementRole';
import Answer from './models/Answer';
import Attendance from './models/Attendance';
import Grade from './models/Grade';
import Material from './models/Material';
import Meeting from './models/Meeting';
import Role from './models/Role';
import RoleUser from './models/RoleUser';
import Schedule from './models/Schedule';
import Student from './models/Student';
import Subject from './models/Subject';
import Task from './models/Task';
import Teacher from './models/Teacher';
import User from './models/User';

Vue.use(Vuex);

// Create a new instance of Database.
const database = new VuexORM.Database();

database.register(Announcement);
database.register(AnnouncementRole);
database.register(Answer);
database.register(Attendance);
database.register(Grade);
database.register(Material);
database.register(Meeting);
database.register(Role);
database.register(RoleUser);
database.register(User);
database.register(Schedule);
database.register(Student);
database.register(Subject);
database.register(Task);
database.register(Teacher);

// Create Vuex Store and register database through Vuex ORM.
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alert,
    announcements,
    answers,
    auth,
    confirmation,
    grades,
    info,
    materials,
    meetings,
    roles,
    tasks,
    schedules,
    students,
    subjects,
    teachers,
    users,
  },
  plugins: [VuexORM.install(database)],
});
