import dayjs from '../../bootstrap/dayjs';
import Base from './Base';
import Student from './Student';
import Task from './Task';

export default class Answer extends Base {
  static entity = 'answers';

  static fields() {
    return {
      id: this.attr(null),
      student_nisn: this.attr(null),
      task_id: this.attr(null),
      text: this.attr(null),
      score: this.attr(null),
      comment: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      student: this.belongsTo(Student, 'student_nisn'),
      task: this.belongsTo(Task, 'task_id'),
    };
  }

  get createdAtJS() {
    return dayjs(this.created_at);
  }

  get updatedAtJS() {
    return dayjs(this.updated_at);
  }
}
