import api from '..';

export default {
  fetch(params) {
    return api.get('v1/schedules', { params });
  },

  store(data) {
    return api.post('v1/schedules', data);
  },

  update(data) {
    return api.patch(`v1/schedules/${data.id}`, data);
  },

  destroy(ID) {
    return api.delete(`v1/schedules/${ID}`);
  },
};
