<template>
  <div class="d-flex min-vh-100">
    <Sidebar />

    <div class="flex-grow-1 overflow-hidden">
      <Header />
      <slot />
    </div>

    <AlertModal />
    <ConfirmationModal />
  </div>
</template>

<script>
import AlertModal from '../components/alert/AlertModal.vue';
import ConfirmationModal from '../components/confirmartion/ConfirmationModal.vue';
import Header from '../components/layout/Header.vue';
import Sidebar from '../components/layout/Sidebar.vue';

export default {
  components: { AlertModal, ConfirmationModal, Header, Sidebar },
};
</script>
