import Base from './Base';

export default class AnnouncementRole extends Base {
  static entity = 'announcementRole';

  static primaryKey = ['announcement_id', 'role_code'];

  static fields() {
    return {
      announcement_id: this.attr(null),
      role_code: this.attr(null),
    };
  }
}
