<template>
  <Error v-if="$store.state.info.error" />

  <component :is="$route.meta.layout" v-else-if="$route.meta.layout">
    <router-view />
  </component>

  <router-view v-else />
</template>

<script>
import Error from "./views/Error.vue";

export default {
  components: { Error },

  metaInfo() {
    const $vm = this;

    return {
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - E-learning` : "E-learning";
      },

      changed(newInfo) {
        $vm.$store.commit("info/setMeta", newInfo);
      },
    };
  },
};
</script>

<style lang="scss">
@import "./assets/scss/main";
</style>
