<template>
  <main class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="text-danger text-center px-32">
      <ExclamationOctagonIcon class="mb-24" />
      <h1 class="fs-32 mb-24">{{ $store.state.info.error.title }}</h1>
      <router-link
        class="btn btn-danger"
        :to="`/${routeNamespace}/beranda`"
        v-if="$store.state.info.error.code === 'middleware_role'"
      >
        Kembali
      </router-link>
      <button
        class="btn btn-danger"
        @click="$router.backOrPush(`/${routeNamespace}/beranda`)"
        v-else
      >
        Kembali
      </button>
    </div>
  </main>
</template>

<script>
import { getRouteNamespace } from '../store/modules/info';

import ExclamationOctagonIcon from 'bootstrap-icons/icons/exclamation-octagon.svg';

export default {
  components: { ExclamationOctagonIcon },

  computed: {
    routeNamespace() {
      if (!this.$auth.isLoggedIn) return this.$store.state.info.routeNamespace;
      return getRouteNamespace(this.$auth.user.get('roles[0].code'));
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  font-size: rem(64);
}
</style>
