import { Model } from '@vuex-orm/core';
import Material from './Material';
import Meeting from './Meeting';
import Schedule from './Schedule';
import Task from './Task';

export default class Subject extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'subjects';

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      materials: this.hasMany(Material, 'grade_id'),
      meetings: this.hasMany(Meeting, 'student_id'),
      schedules: this.hasMany(Schedule, 'subject_id'),
      tasks: this.hasMany(Task, 'subject_id'),
    };
  }

  get editRoutePath() {
    return `/admin/pelajaran/${this.id}/ubah`;
  }
}
