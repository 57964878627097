import { Model } from '@vuex-orm/core';
import Schedule from './Schedule';
import User from './User';

export default class Teacher extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'teachers';

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      nip: this.attr(null),
      nuptk: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      schedules: this.hasMany(Schedule, 'teacher_id'),
    };
  }
}
