import api from '..';

export default {
  fetch(params) {
    return api.get('v1/tasks', { params });
  },

  store(data) {
    return api.post('v1/tasks', data);
  },

  update(data) {
    return api.patch(`v1/tasks/${data.id}`, data);
  },

  destroy(ID) {
    return api.delete(`v1/tasks/${ID}`);
  },

  answer(ID, data) {
    return api.patch(`v1/tasks/${ID}/answer`, data);
  },
};
