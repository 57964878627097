import { VTooltip } from 'v-tooltip';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import Alert from './plugins/Alert';
import Auth from './plugins/Auth';
import Confirmation from './plugins/Confirmation';

import './registerServiceWorker';

Vue.config.productionTip = false;

Vue.use(VueMeta);
Vue.use(Alert);
Vue.use(Auth);
Vue.use(Confirmation);

Vue.directive('tooltip', VTooltip);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
