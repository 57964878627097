import { Model } from '@vuex-orm/core';

export default class RoleUser extends Model {
  static entity = 'roleUser';

  static primaryKey = ['role_code', 'user_id'];

  static fields() {
    return {
      role_code: this.attr(null),
      user_id: this.attr(null),
    };
  }
}
