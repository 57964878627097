import api from '..';

export default {
  fetch(params) {
    return api.get('v1/users', { params });
  },

  store(data) {
    return api.post('v1/users', data);
  },

  update(data) {
    return api.patch(`v1/users/${data.id}`, data);
  },

  destroy(ID) {
    return api.delete(`v1/users/${ID}`);
  },
};
